<template>
    <div>
        <ClientOnly>
            <LMap
                v-model:zoom="zoom"
                v-model:center="center"
                :options="{attributionControl: false}"
                style="height: 400px"
                @update:bounds="loadOrganisationOptions"
                @ready="setInitalZoom">
                <LTileLayer
                    attribution="<a href=&quot;https://leafletjs.com/&quot; target=&quot;_blank&quot;>Leaflet</a> | &copy; <a href=&quot;https://www.openstreetmap.org/copyright&quot; target=&quot;_blank&quot;>OpenStreetMap</a> contributors"
                    url="https://{s}.tile.osm.org/{z}/{x}/{y}.png" />
                <LControlAttribution
                    position="bottomright"
                    prefix="" />


                <LControl
                    class="custom-control"
                    position="bottomright">
                    <p @click="setCenter">
                        <FontAwesomeIcon icon="location-arrow" />
                    </p>
                </LControl>

                <LMarker
                    v-for="organisationOption in organisationOptions"
                    :key="organisationOption.id"
                    :lat-lng="organisationOption.coordinates"
                    @click="$emit('update:organisation', organisationOption)">
                    <LPopup>
                        <div class="d-flex flex-column">
                            {{ organisationOption.name }}
                            <br>
                            <small class="text-muted">{{ organisationOption.street }} {{ organisationOption.housenumber }}
                            </small>
                            <small class="text-muted">{{ organisationOption.zipcode }} {{ organisationOption.city }}</small>
                        </div>
                    </LPopup>
                </LMarker>
            </LMap>
        </ClientOnly>
        <div
            v-if="organisation"
            class="mb-2 mt-3">
            <BaseInput label="Deine gewählte Organisation:">
                <div class="selectedOrganisation pl-3">
                    {{ organisation.name }}
                    <br>
                    <small class="text-muted">{{ organisation.city }}</small>
                </div>
            </BaseInput>
        </div>
    </div>
</template>
<script>
import uniqBy from 'lodash/uniqBy'

export default {
    name: 'JuleicaOrganisationsMap',
    props: {
        organisation: {
            required: true
        }
    },
    data() {
        return {
            zoomChanged: false,
            zoom: 4,
            center: {lat: 50.93073802371819, lng: 10.524902343750002},
            organisationOptions: [],
        }
    },
    watch: {
        organisation: {
            handler(organisation) {
                if (organisation) {
                    this.center = organisation.coordinates
                    this.organisationOptions.push(organisation)
                    this.organisationOptions = uniqBy(this.organisationOptions, 'id')
                    if (!this.zoomChanged) {
                        this.zoom = 14
                        this.zoomChanged = true;
                    }
                }
            },
            deep: true,
            immediate: true
        },
        zoom() {
            this.zoomChanged = true;
        }
    },
    methods: {
        setInitalZoom() {
            setTimeout(() => {
                if (!this.zoomChanged) {
                    this.zoom = 5
                }
            }, 300)
        },

        setCenter() {
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition((position) => {
                    this.zoom = 12
                    setTimeout(() => {
                        this.center = {lat: position.coords.latitude, lng: position.coords.longitude}
                    }, 300)
                })
            }
        },

        async loadOrganisationOptions(bounds) {
            try {
                let loadedOrganisationOptions = (await $lara.get(this.$apiRoute('spa.frontend.organisationsmap.index'),
                                                                 {
                                                                     params: {
                                                                         northEastLat: bounds._northEast.lat,
                                                                         northEastLng: bounds._northEast.lng,
                                                                         southWestLat: bounds._southWest.lat,
                                                                         southWestLng: bounds._southWest.lng,
                                                                     }
                                                                 }
                )).data

                //Keep only 30 entries
                this.organisationOptions.splice(0, this.organisationOptions.length - 30)
                loadedOrganisationOptions = this.organisationOptions.concat(loadedOrganisationOptions)

                this.organisationOptions = uniqBy(loadedOrganisationOptions, 'id')


            } catch (error) {
                console.log(error);
                this.useNotification().error(error.response ? this.$t('axios.' + error.response.data.message.replace(".", "")) : "Ein Fehler beim Laden der Organisationen ist aufgetreten.")
            }
        },
    }

}
</script>
<style scoped>
    .selectedOrganisation {
        padding: 0.5em;
        border-style: solid;
        border-width: 1px;
        border-color: $gray-400;
        border-radius: 4px;
    }

    .custom-control {
        border: 2px solid rgba(0, 0, 0, 0.2);
        border-radius: 2px;
        width: 34px;
        height: 34px;
        background-color: white;
        padding: 6px 9px;
        cursor: pointer;
    }
</style>
